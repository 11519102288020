import {mapMutations, mapActions, mapGetters} from "vuex";
import {dynamicHead} from "../../mixins/dynamic-head";

//sections

export default {
    name: "articles",
    components: {},
    mixins: [dynamicHead],
    data() {
        return {
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'articles', title: this.$t('blog.title'), slug: 'articles'}
            ],
            listArticles: []
        }
    },
    mounted() {
    },
    created() {
        this.getPageContent('news').then(() => {
            this.setPageItem(this.pageContent)
            document.title = `${this.pageContent.title}`
        })
        this.getArticles().then(() => {
            this.articles.data.forEach(el => {
                this.listArticles.push(el)

            })
        })
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            articles: 'articles/articles',
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getArticles: 'articles/GET_ARTICLES',
            getPageContent: 'setting/GET_PAGE_CONTENT'
        }),
        ...mapMutations({
            moreArticles: 'articles/SET_ARTICLES_PAGE',
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
        }),
        getMoreArticles() {
            this.moreArticles()
            this.getArticles().then(() => {
                this.listArticles = []
                this.articles.data.forEach(el => {
                    this.listArticles.push(el)
                })
            })
        }
    }
}
